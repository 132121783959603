import $ from "jquery";

$(document).ready(() => {
  const baseHref = document.getElementsByTagName("base")[0].href;

  fetch(`${baseHref}api/v1/SellLocation/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      createMap(data);
    })
    .catch(err => {
      console.warn(err);
    });

  function createMap(locations) {
    const map = new google.maps.Map(
      document.getElementById("selling-point-map"), {
        zoom: 7,
        minZoom: 7.5,
        zoomControl: true,
        center: new google.maps.LatLng(52.092876, 5.10448),
        disableDefaultUI: true
      }
    );

    const markerIcon = {
      url: baseHref +
        "/_resources/themes/ijsblokjes/images/icons/location-blue.svg",
      scaledSize: new google.maps.Size(25, 40)
    };

    const zipcodeMarker = {
      url: baseHref +
        "/_resources/themes/ijsblokjes/images/icons/location-orange.svg",
      scaledSize: new google.maps.Size(40, 60)
    };

    let marker, i;
    const bounds = new google.maps.LatLngBounds();
    let activeInfoWindow;

    for (i = 0; i < locations.items.length; i++) {
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          locations.items[i].Latitude,
          locations.items[i].Longitude
        ),
        map: map,
        icon: markerIcon,
        animation: google.maps.Animation.DROP
      });

      bounds.extend(marker.position);

      google.maps.event.addListener(
        marker,
        "click",
        (function (marker, i) {
          const infowindow = new google.maps.InfoWindow({
            content: '<div class="infoWindowTab"><div><h3>' +
              locations.items[i].Location +
              "</h3><p>" +
              locations.items[i].Adressdetail +
              "</p>"
          });

          return function () {
            if (activeInfoWindow) {
              activeInfoWindow.close();
            }
            infowindow.open(map, marker);
            activeInfoWindow = infowindow;
          };
        })(marker, i)
      );
    }

    //Center map and adjust Zoom based on the position of all markers.
    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);

    // Create the search box and link it to the UI element.
    var input = document.getElementById("pac-input");
    var searchBox = new google.maps.places.SearchBox(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", function () {
      searchBox.setBounds(map.getBounds());
    });

    // var markers = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", function () {
      var places = searchBox.getPlaces();
      console.log(places);

      if (places.length == 0) {
        return;
      }
      // For each place, get the icon, name and location.
      var bounds = new google.maps.LatLngBounds();

      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        var icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25)
        };

        console.log(place.geometry.location.lat());

        marker = new google.maps.Marker({
          position: new google.maps.LatLng(
            place.geometry.location.lat(),
            place.geometry.location.lng()
          ),
          map: map,
          icon: zipcodeMarker,
          animation: google.maps.Animation.DROP
        });

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
      map.setZoom(12);
    });
  }
});
