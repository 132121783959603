import $ from 'jquery';

$(document).ready(() => {
  const ctaField = $('.js-cta-field');
  const ctaPopup = $('.js-cta-popup');
  const ctaSubmit = $('.cta-calculating__form input[type=submit]');

  ctaSubmit.click((e) => {
    e.preventDefault();

    if (ctaField.val().length >= 6) {
      ctaPopup.addClass('js-cta-popup-show')
    } else {
      ctaPopup.html('<p>Vul een correcte postcode in</p>')
      ctaPopup.addClass('js-cta-popup-show')
    }
  });
});
