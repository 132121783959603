import $ from 'jquery';

$(document).ready(() => {
  const otherPersonFields = $('#OtherPersonFields');
  otherPersonFields.hide();

  $('#Form_Cart_Same_2').on('change', function () {
    if ($(this).prop('checked')) {
      otherPersonFields.show(250, 'swing')
    }
  })

  $('#Form_Cart_Same_1').on('change', function () {
    if ($(this).prop('checked')) {
      otherPersonFields.hide(250, 'swing')
    }
  })
});
