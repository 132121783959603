import $ from 'jquery';

$(document).ready(() => {
  const cartOverview = $('#cart-overview');
  const cartToggle = $('.js-cart-toggle');
  const menuToggle = $('.js-hidden-menu-toggle');
  const menuContainer = $('.js-hidden-menu-container');
  const cartBtn = $('#cart__btn');

  menuToggle.click(() => {
    menuContainer.toggleClass('js-hidden-menu-is-visible');
    cartBtn.toggleClass('js-hidden-menu-shop-style');
    menuToggle.toggleClass('js-btn-active');
    cartOverview.removeClass('js-cart-overview-open');
    cartToggle.removeClass('js-cart-toggle--active');
  });
});
