import $ from "jquery";
import validate from "jquery-validation";

$(document).ready(() => {
  $.extend($.validator.messages, {
    required: '<span class="validation-message">Dit veld is verplicht</span>'
  });

  $("#Form_Cart").validate({
    rules: {
      ShippingEmail: {
        required: true,
        email: true
      },
      BillingEmail: {
        required: true,
        email: true
      },
      ShippingPhone: {
        number: true,
        minlength: 10,
        maxlength: 10
      },
      BillingPhone: {
        number: true,
        minlength: 10,
        maxlength: 10
      },
      Same: {
        required: true
      },
      ShippingCalendar: {
        date: false
      },
      deliveryTimeSlot: {
        required: true
      }
    },
    messages: {
      ShippingEmail: {
        email: '<span class="validation-message">Voor een juist email adres in</span>'
      },
      BillingEmail: {
        email: '<span class="validation-message">Voor een juist email adres in</span>'
      },
      ShippingPhone: {
        number: '<span class="validation-message">Voer een juiste telefoonnummer in</span>',
        minlength: '<span class="validation-message">Gebruik 10 cijfers</span>',
        maxlength: '<span class="validation-message">Gebruik 10 cijfers</span>'
      },
      BillingPhone: {
        number: '<span class="validation-message">Voer een juiste telefoonnummer in</span>',
        minlength: '<span class="validation-message">Gebruik 10 cijfers</span>',
        maxlength: '<span class="validation-message">Gebruik 10 cijfers</span>'
      }
    }
  });
});
