import $ from 'jquery';

$(document).ready(() => {
  const cartToggle = $('.js-cart-toggle');
  const addProductCartToggle = $('.js-add-product-cart-toggle');
  const cartOverview = $('#cart-overview');
  const menuToggle = $('.js-hidden-menu-toggle');

  cartToggle.toggleClass(window.localStorage.cartBtnIsActive)
  cartOverview.toggleClass(window.localStorage.cartIsActive)

  cartToggle.click(() => {
    if ($(window).width() < 470) {
      location.reload();
    }

    if (window.localStorage.cartIsActive != "js-cart-overview-open") {
      cartToggle.toggleClass("js-cart-toggle--active", true);
      window.localStorage.cartBtnIsActive = "js-cart-toggle--active";

      cartOverview.toggleClass("js-cart-overview-open", true);
      window.localStorage.cartIsActive = "js-cart-overview-open";
    } else {
      cartToggle.toggleClass("js-cart-toggle--active", false);
      window.localStorage.cartBtnIsActive = "";

      cartOverview.toggleClass("js-cart-overview-open", false);
      window.localStorage.cartIsActive = "";
    }
  })

  addProductCartToggle.click(() => {
    if ($(window).width() < 470) {
      location.reload();
    }

    if (window.localStorage.cartIsActive != "js-cart-overview-open") {
      cartToggle.toggleClass("js-cart-toggle--active", true);
      window.localStorage.cartBtnIsActive = "js-cart-toggle--active";

      cartOverview.toggleClass("js-cart-overview-open", true);
      window.localStorage.cartIsActive = "js-cart-overview-open";
    }
  });

  $('.navigation__main-menu a').click(() => {
    window.localStorage.cartIsActive = "";
    window.localStorage.cartBtnIsActive = "";
  });

  $('.js-disable-cart-link').click(() => {
    window.localStorage.cartIsActive = "";
    window.localStorage.cartBtnIsActive = "";
  });

  menuToggle.click(() => {
    window.localStorage.cartIsActive = "";
    window.localStorage.cartBtnIsActive = "";
  });

  if (window.location.href.indexOf('cart') > -1) {
    window.localStorage.cartIsActive = "";
    window.localStorage.cartBtnIsActive = "";
  }


  $('body').on('change', '.productAmountInput', function () {
    const val = $(this).val();
    const slug = $(this).attr('ID');
    const baseHref = document.getElementsByTagName("base")[0].href;

    if (val > 150) {
      const val = 150
      $.ajax({
        type: "POST",
        url: `${baseHref}home/addMoreProducts`,
        data: {
          val,
          slug
        },
        success: function (data) {
          location.reload()
        }
      });
    } else {
      $.ajax({
        type: "POST",
        url: `${baseHref}home/addMoreProducts`,
        data: {
          val,
          slug
        },
        success: function (data) {
          location.reload()
        }
      });
    }
  });
});

$('.js-qty-trigger').click(function () {
  const url = $(this).attr('data-qty-link');

  $.ajax({
    type: "POST",
    url: url,
    success: function (data) {
      location.reload()
    }
  });
});
