import $ from "jquery";
import { slick } from "slick-carousel";

$(document).ready(() => {
  productSummarySlider();
  productPageSlider();
  productsCubesSlider();
  productsCubesNav();

  document.addEventListener("turbolinks:load", function() {
    productSummarySlider();
    productPageSlider();
    productsCubesSlider();
    productsCubesNav();
  });

  $(".js-cubes-slider > .slick-list .slick-slide").click(e => {
    e.preventDefault();
  });

  const slide = $(".js-weight-nav .slick-slide");

  slide.click(function(e) {
    console.log($(this).find("input"));
    $(this)
      .find("input")
      .prop("checked", true);
  });
});

function productSummarySlider() {
  $(".js-products__slider").slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    infinite: false,
    prevArrow:
      '<button class="slick-prev slider__controls slider__prev"></button>',
    nextArrow:
      '<button class="slick-next slider__controls slider__next"></button>',
    responsive: [
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
}

function productPageSlider() {
  $(".js-product-page-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: false,
    prevArrow:
      '<button class="slick-prev slider__controls slider__prev"></button>',
    nextArrow:
      '<button class="slick-next slider__controls slider__next"></button>'
  });
}

function productsCubesSlider() {
  $(".js-cubes-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    fade: true,
    speed: 0,
    infinite: false,
    arrows: false,
    asNavFor: ".js-weight-nav"
  });
}

function productsCubesNav() {
  $(".js-weight-nav").slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    infinite: false,
    arrows: false,
    asNavFor: ".js-cubes-slider",
    focusOnSelect: true
  });
}
