import $ from 'jquery';

$(document).ready(() => {
  const anchorForm = $('.js-fixed-window-position');
  const incrementBtn = $('.js-keep-scrollposition')
  const href = location.href;

  incrementBtn.click(() => {
    window.localStorage.scrolltop = $(window).scrollTop();
  });

  anchorForm.submit(() => {
    window.localStorage.scrolltop = $(window).scrollTop();
  });

  if ($(location).attr('search')) {
    $(window).scrollTop(window.localStorage.scrolltop);
  }

  if (href.indexOf('cart') >= 0) {
    $(window).scrollTop(window.localStorage.scrolltop);
  }

  $('#Form_Cart_action_goToCheckout').click(() => {
    window.localStorage.scrolltop = 0;
  })

  $('.navigation__login-btn').click(() => {
    window.localStorage.scrolltop = 0;
  });
});
