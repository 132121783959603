import $ from 'jquery';

$(document).ready(() => {
  const element = $('.js-tocomma');

  element.each(function () {
    const el = $(this);

    const text = el.text();
    const result = text.replace('.', ',');

    el.text(result);
  });
});
