import $ from "jquery";

$(document).ready(() => {

  $('.cube-holder .tablinks').click(function () {
    const weight = $(this).find('input').val();
    const tabContainer = $('.cube-holder .products__type-holder');
    const tabcontent = $('.cube-holder .tabcontent');
    const tablinks = $('.cube-holder .tablinks');
    var i;

    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks.removeClass('active')
    tabContainer.find('.' + weight).addClass('active')
    $('#' + weight).css('display', "block");
    $('#' + weight).find('.' + weight).addClass('active')

  });

  $('.crushed-holder .tablinks').click(function () {
    const weight = $(this).find('input').val();
    const tabContainer = $('.crushed-holder .products__type-holder');
    const tabcontent = $('.crushed-holder .tabcontent');
    const tablinks = $('.crushed-holder .tablinks');
    var i;

    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks.removeClass('active')
    tabContainer.find('.' + weight).addClass('active')
    $('#' + weight).css('display', "block");
    $('#' + weight).find('.' + weight).addClass('active')

  });
});
