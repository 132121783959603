const closeModal = document.querySelector('.modal__close');
const modal = document.querySelector('.modal');

if(modal !== null){
  if(window.localStorage.getItem('modalShowed') != 'shown') {
    setTimeout(function() {
      modal.classList.add('is-active');
      window.localStorage.setItem('modalShowed', 'shown');
    }, 2000);

    if(closeModal) closeModal.addEventListener('click', () => {
      modal.classList.remove('is-active');
      modal.classList.add('not-active');
      window.localStorage.setItem('modalShowed', 'shown');
    });
  } else {
    modal.classList.add('not-active');
  }
}
