import $ from 'jquery';

$(document).ready(() => {
  $('#Contactpersoon').before('<h3 class="shippingtitle">Verzendgegevens</h3>');
  $('#CallMeGroup').after('<h4 class="shippingtitlesmall">Afleveradres</h4>');
  $('#Form_Cart_ShippingCalendar_Holder').before('<h3 class="shippingCalanderTitle">Kies een bezorgdag</h3>');

  const billingForm = $('#Factuurgegevens').wrap('<div >').parent().html();
  const shippingTitle = $('.shippingtitle').wrap('<div >').parent().html();
  const shippingTitleSmall = $('.shippingtitlesmall').wrap('<div >').parent().html();
  const contactPerson = $('#Contactpersoon').wrap('<div >').parent().html();
  const otherPerson = $('#OtherPersonFields').wrap('<div >').parent().html();
  const callMe = $('#CallMeGroup').wrap('<div >').parent().html();
  const copyFromBilling = $('#Form_Cart_OneAddressForEverything_Holder').wrap('<div >').parent().html()
  const shippingDetails = $('#Verzendgegevens').wrap('<div >').parent().html();
  const orderRemark = $('#Form_Cart_OrderRemark_Holder').wrap('<div >').parent().html();
  const shippingCalanderTitle = $('.shippingCalanderTitle').wrap('<div >').parent().html();
  const calander = $('#Form_Cart_ShippingCalendar_Holder').wrap('<div >').parent().html();
  const payment = $('#Form_Cart_paymentMethod_Holder').wrap('<div >').parent().html();
  const timeSlots = $('#deliveryTimeSlotGroup').wrap('<div >').parent().html();
  const priceTable = $('.cart-proces__total').wrap('<div >').parent().html();

  if (copyFromBilling == undefined) {
    const leftside = '<div class="form-wrapper__left">' + billingForm + shippingTitle + contactPerson + otherPerson + callMe + shippingTitleSmall + shippingDetails + orderRemark + '</div>';
    const rightside = '<div class="form-wrapper__right">' + priceTable + shippingCalanderTitle + calander + timeSlots + payment + '</div>'
    $('.CartStep2 #Form_Cart fieldset').html('<div id="form-wrapper">' + leftside + rightside + '</div>')
  } else {
    const leftside = '<div class="form-wrapper__left">' + billingForm + shippingTitle + contactPerson + otherPerson + callMe + shippingTitleSmall + copyFromBilling + shippingDetails + orderRemark + '</div>';
    const rightside = '<div class="form-wrapper__right">' + priceTable + shippingCalanderTitle + calander + timeSlots + payment + '</div>'
    $('.CartStep2 #Form_Cart fieldset').html('<div id="form-wrapper">' + leftside + rightside + '</div>')
  }
});
