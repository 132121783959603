document.addEventListener('touchstart', {
  passive: true
});

/* Inits */
import './inits/slick';

/* Components */
import './components/step2Elements';
import './components/ieBanner';
import './components/maps';
import './components/dotReplace'
import './components/homepageProduct'
import './components/deliverycostFormValidation';
import './components/formRequiredIndication'
import './components/shippingFormCopy';
import './components/step2FormValidation';
import './components/scrollPosition';
import './components/modal';


/* Events */
import './events/hiddenMenu';
import './events/cart';
import './events/ctaPopup';
import './events/otherPersonToggle'


/* If we put this on top it wil break other script. Keep it at the bottom */
import './components/shippingCalander'
