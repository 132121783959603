import $ from "jquery";

$(document).ready(() => {
  const zipcodeForm = $("#deliverycostform");
  const zipcodeFormSubmit = $("#deliverycostform input[type=submit]");
  const toCheckoutBtn = $(".you-shall-not-pass");
  const calcErrorMessage = $(".js-calculating-message");
  const zipcodeSucces = $('.js-deliverycosts-succes');

  toCheckoutBtn.prop("disabled", true);
  toCheckoutBtn.css("opacity", "0.5");
  calcErrorMessage.hide();

  const urlHasSearch = $(location).attr('search');

  if (urlHasSearch === '') {
    $('.js-calculating-message').show()
  } else {
    $('.js-calculating-message').hide()
    zipcodeSucces.addClass("js-cta-popup-show")
  }

  zipcodeFormSubmit.click(e => {
    e.preventDefault();
    const inputValue = $(".js-check-zipcode").val();

    if (inputValue.match(/^[1-9]{1}[0-9]{3} ?[a-zA-Z]{2}$/)) {
      zipcodeForm.submit();
    } else {
      zipcodeSucces.removeClass('js-cta-popup-show');
      $(".js-deliverycosts-error").addClass("js-cta-popup-show");
    }
  });
});
