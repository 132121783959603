import $ from 'jquery';

$(document).ready(() => {
  const requiredFields = $('input[required]:visible');
  const calanderLabel = $('#Form_Cart_ShippingCalendar_Holder label');
  const htmlContent = '<span class="input-required-text">Verplicht</span><span class="input-required-indicator">*</span>';

  requiredFields.each(function (index) {
    $(this).parent().find('label').append(htmlContent);
  });

  calanderLabel.append(htmlContent);

  $('#Form_Cart_ShippingCalendar').prop('readonly', false);
});
