import $ from "jquery";

$(document).ready(() => {
  const checkField = $("#Form_Cart_OneAddressForEverything");
  const checkFieldClick = $("#Form_Cart_OneAddressForEverything_Holder label");

  const billingFirstName = $("#Form_Cart_BillingFirstName");
  const billingLastName = $("#Form_Cart_BillingLastName");
  const billingEmail = $("#Form_Cart_BillingEmail");
  const billingPhone = $("#Form_Cart_BillingPhone");
  const billingKVK = $("#Form_Cart_BillingKVK");

  const shippingFirstName = $("#Form_Cart_ShippingFirstName");
  const shippingLastName = $("#Form_Cart_ShippingLastName");
  const shippingEmail = $("#Form_Cart_ShippingEmail");
  const shippingPhone = $("#Form_Cart_ShippingPhone");
  const shippingKVK = $("#Form_Cart_ShippingKVK");
  const shippingCountry = $('#Form_Cart_ShippingCountry_Holder');

  // Dit is op de label
  checkFieldClick.click(e => {
    copyFormValues(e);
  });

  billingFirstName.change(function () {
    shippingFirstName.val($(this).val());
  });

  billingLastName.change(function () {
    shippingLastName.val($(this).val());
  });

  billingEmail.change(function () {
    shippingEmail.val($(this).val());
  });

  billingPhone.change(function () {
    shippingPhone.val($(this).val());
  });

  billingKVK.change(function () {
    shippingKVK.val($(this).val());
  });

  shippingFirstName.parent().parent().css('display', 'none');
  shippingLastName.parent().parent().css('display', 'none');
  shippingEmail.parent().parent().css('display', 'none');
  shippingPhone.parent().parent().css('display', 'none');
  shippingKVK.parent().parent().css('display', 'none');
  shippingCountry.parent().css('display', 'none');

  function copyFormValues(event) {
    event.preventDefault();
    const chekboxState = checkField.prop("checked");

    // All the Shipping details
    const shippingStreetAndNumber = $(
      "#Form_Cart_ShippingStreetAndNumber"
    );
    const shippingCompanyName = $("#Form_Cart_ShippingCompanyName");
    const shippingCity = $("#Form_Cart_ShippingCity");

    // Billing fields
    const billingStreetAndNumber = $("#Form_Cart_BillingStreetAndNumber");
    const billingCompanyName = $("#Form_Cart_BillingCompanyName");
    const billingCity = $("#Form_Cart_BillingCity");

    if (billingStreetAndNumber.val() == "") {
      $("input+small").remove();
      billingStreetAndNumber.after(
        "<small>Vul eerst uw straatnaam en huisnummer in.</small>"
      );

      $('html, body').animate({
        scrollTop: billingStreetAndNumber.offset().top - 200
      }, 1000);
    } else if (billingCity.val() == "") {
      $("input+small").remove();
      billingCity.after(
        "<small>Vul eerst uw stad in.</small>"
      );

      $('html, body').animate({
        scrollTop: billingCity.offset().top - 200
      }, 1000);
    } else {
      if (chekboxState == false) {
        $("input+small").remove();
        checkField.prop("checked", true);

        // Filling the form
        shippingStreetAndNumber.val(billingStreetAndNumber.val());
        shippingCompanyName.val(billingCompanyName.val());
        shippingCity.val(billingCity.val());
      } else if (chekboxState == true) {
        checkField.prop("checked", false);

        // Empty the fields
        shippingStreetAndNumber.val('');
        shippingCompanyName.val('');
        shippingCity.val('');
      }
    }
  }
});
