import $ from "jquery";
import "flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js";

if (window.location.href.indexOf("step2") > -1) {
  $(document).ready(() => {
    // Get all the selected disabled days
    const input = document.getElementById("flatpickr-disabled-days").value;
    let disabledDays = JSON.parse(input);

    // Filter the selected disabled dates into "Red" and "Orange"
    const redDaysArr = disabledDays.filter(function(index) {
      return index.type == "Red";
    });

    const orangeDaysArr = disabledDays.filter(function(index) {
      return index.type == "Orange";
    });

    const betweenRedDates = [];

    // formats and bundles all red days
    redDaysArr.forEach(index => {
      const allRedDates = getDates(new Date(index.from), new Date(index.to));

      allRedDates.forEach(date => {
        //parse dates to correct format before pushing
        betweenRedDates.push(formatDate(date));
      });
    });

    //const betweenRedDatesFlattend = betweenRedDates.flat(1);

    const betweenOrangeDates = [];

    // formats and bundles all orange days
    orangeDaysArr.forEach(index => {
      const allOrangeDates = getDates(new Date(index.from), new Date(index.to));

      allOrangeDates.forEach(date => {
        //parse dates to correct format before pushing
        betweenOrangeDates.push(formatDate(date));
      });
    });

    //const betweenOrangeDatesFlattend = betweenOrangeDates.flat(1);

    let firstThreeDatesDone = 0;
    let today = new Date().toLocaleDateString("NL");
    var to = new Date();
    to.setDate(to.getDate() + 2);
    disabledDays.push({from: today, to: to, type: "red"});

    if (input) {
      flatpickr("#Form_Cart_ShippingCalendar", {
        onDayCreate: function(dObj, dStr, fp, dayElem) {
          const currentDate = dayElem.getAttribute("aria-label");
          if(firstThreeDatesDone > 0 && firstThreeDatesDone < 3|| new Date(currentDate).toLocaleDateString("NL") === today){
            firstThreeDatesDone++;
            dayElem.className += " redDay";
          }else{
            if (betweenRedDates.indexOf(currentDate) > -1) {
              dayElem.className += " redDay";
            }

            if (betweenOrangeDates.indexOf(currentDate) > -1) {
              dayElem.className += " orangeDay";
            }
          }
        },
        appendTo: document.querySelector(
          "#Form_Cart_ShippingCalendar_Holder div.middleColumn"
        ),
        minDate: "today",
        disable: disabledDays,
        // disable: [
        //   disabledDays
        // ],
        locale: Dutch,
        ignoredFocusElements: [document.body],
        closeOnSelect: false,
        dateFormat: "d-m-Y",
        ariaDateFormat: "Y-m-d"
      });
    }
  });

  const calanderLegend =
    '<ul class="calander-legend"><li class="calander-legend__item"><div class="calander-legend__color calander-legend__color--green"></div><p>Groen: Bestellen is mogelijk</p></li><li class="calander-legend__item"><div class="calander-legend__color calander-legend__color--orange"></div><p>Oranje: Bestellingen kunnen alleen nog in overleg telefonisch of per e-mail worden gedaan.</p></li><li class="calander-legend__item"><div class="calander-legend__color calander-legend__color--red"></div><p>Rood: Het plaatsen van een bestelling voor deze dag is helaas niet langer mogelijk</p></li></ul>';

  $("#Form_Cart_ShippingCalendar_Holder div.middleColumn").after(
    calanderLegend
  );
}

// Get all the dates that are in between
function getDates(startDate, endDate) {
  var dates = [],
    currentDate = startDate,
    addDays = function(days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
}

// formats date in American readable string
function formatDate(date) {
  const formatted = new Date(date);

  const month = formatted.getMonth() + 1;
  const getMonth = month < 10 ? "0" + month : month;

  const getDay =
    formatted.getDate() < 10 ? "0" + formatted.getDate() : formatted.getDate();

  return formatted.getFullYear() + "-" + getMonth + "-" + getDay;
}
